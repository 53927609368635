<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>CREAR TESTIMONIOS</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Nombre</label>
          <input type="text" class="form-control" v-model="testimonial.name">
          <label>Commentario</label>
          <textarea class="form-control" v-model="testimonial.comment"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Role</label>
          <input type="text" class="form-control" v-model="testimonial.role">
          <label>File</label>
          <input type="file" class="form-control" @change="onChange">
          <button class="btn bg-gradient-success w-100 mt-2" @click="createTestimonial">CREAR TESTIMONIO</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Swal from "sweetalert2";
import UploadFile from "@/utils/uploadFile";
import {post} from "@/utils/httpRequest";

export default {
  data: () => ({
    testimonial: {
      name: '',
      comment: '',
      rate: '5',
      role: '',
    },
    tempFileRelated: null,
  }),
  methods: {
    async onChange(e){
      this.tempFileRelated = e.target.files[0];
    },
    async createTestimonial(){
      if(this.testimonial.name !=='' &&
          this.testimonial.comment !== '' &&
          this.testimonial.rate !== '' &&
          this.testimonial.role !== '' &&
          this.tempFileRelated !== null){
        UploadFile.upload("user-documents/add-file",{file: this.tempFileRelated}).then(async ({data}) => {
          console.log("file related: ", data)
          await post("testimonials/create", {
            ...this.testimonial,
            file_related: data,
          })
          await Swal.fire('Informacion creada correctamente', '', 'success')
         window.location.reload()
        })
      }
    }
  }
}
</script>
<style scoped>

</style>
